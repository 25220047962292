import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../library/button/button';
import FitmentNotesModal from './components/fitment-notes-modal/fitment-notes-modal';

import { trackAction } from '../../shared/utils/analytics/analytics';

function FitmentNotesButton(props) {
  const { selectedFitment } = props;
  const { isGeneric } = selectedFitment || {};

  const [isFitmentNotesOpen, setIsFitmentNotesOpen] = useState(false);
  function toggleFitmentNotes() {
    setIsFitmentNotesOpen(!isFitmentNotesOpen);
  }
  if (!selectedFitment || isGeneric) {
    return null;
  }

  return (
    <>
      {selectedFitment?.vehicleAssemblyId && (
        <Button
          onClick={() => {
            toggleFitmentNotes();
            trackAction('vehicle_fitment_notes_modal_open');
          }}
        >
          View vehicle fitment notes
        </Button>
      )}
      <FitmentNotesModal
        isOpen={isFitmentNotesOpen}
        onClose={() => {
          toggleFitmentNotes();
          trackAction('vehicle_fitment_notes_modal_close');
        }}
        selectedFitment={selectedFitment}
      />
    </>
  );
}

FitmentNotesButton.propTypes = {
  selectedFitment: PropTypes.object.isRequired
};

export default FitmentNotesButton;
